/**
 *
 *
 * DEPRECATED, SEE NEW VERSION AT https://gist.github.com/c-kick/d359fce36257cf4c9fb5ea5f2c0033b6
 *
 *
 * JavaScript function prototype debouncer 3.0 - hnldesign.nl
 * Based on code by Paul Irish and the original debouncing function from John Hann
 * http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
 * Register deBouncer as a function prototype.
 *
 * Usage:
 *
 * original:  window.addEventListener('onorientationchange', myFunction, false);
 * debounced: window.addEventListener('onorientationchange', myFunction.deBounce(100, false, false), false);
 *
 * or
 *
 * orginal:   myFunction(arguments);
 * debounced: myFunction.deBounce(100, true, true)(arguments);
 *
 * @memberOf(Object)
 * @name deBounce
 * @property
 *
 * @param {int} threshold - Time in ms to wait for repeated calls.
 *
 * @param {boolean} execFirst
 *                  True: Function is executed, subsequent calls are stopped during threshold.
 *                  False: calls are stopped during threshold, when it passes, function is called.
 *
 * @param {boolean} execAfterThreshold
 *                  True: continue executing immediately after the threshold has passed, while the function is still being called.
 *                  False: threshold serves as 'cool down' period; only if function calls have ended, and threshold passes, function can be called again.
 *
 * @returns {function} - The debounced function
 */

if (typeof Function.prototype.deBounce !== "function") {
  Object.defineProperty(Function.prototype, "deBounce", {
    value: function (threshold, execFirst, execAfterThreshold) {
      "use strict";
      var func = this;
      func.threshold = threshold;
      if (func.debounced !== undefined) {
        return this.debounced;
      } else {
        func.debounced = function () {
          var obj = this,
            args = arguments;

          function delayed() {
            if (!execFirst) {
              func.apply(obj, args);
            }
            func.timeout = null;
          }

          if (!func.timeout) {
            if (execFirst) {
              func.apply(obj, args);
            }
            func.timeout = setTimeout(delayed, func.threshold || 100);
          } else if (func.timeout && !execAfterThreshold) {
            clearTimeout(func.timeout);
            func.timeout = setTimeout(delayed, func.threshold || 100);
          }
        };
        return func.debounced;
      }
    },
  });
}

/**
 * Wraps the deBouncer for easy use in jQuery
 * Usage:
 * jQdeBounce($, 'smartresize', 'resize', 20); //creates the function
 * $(window).smartresize(function () { //use the function
 * console.log('jquery resize debounce');
 * });
 *
 * @param {object}      $               The jQuery object to register in.
 * @param {function}    createMethod    The debounced method/function to create.
 * @param {function}    originalMethod  The original method/function to use as source
 */
if (window.jQuery) {
  var jQdeBounce = function (
    $,
    createMethod,
    originalMethod,
    thresh,
    execAsap
  ) {
    "use strict";
    $.fn[createMethod] = function (fn) {
      return fn
        ? this.bind(originalMethod, fn.deBounce(thresh, execAsap))
        : this.trigger(createMethod);
    };
  };
}

jQuery(document).ready(function () {
  var hometop = jQuery(".site-container");
  var siteheader = jQuery(".site-header");
  hometop.css("border-top-width", `${siteheader.height()}px`);
  siteheader.css("position", "fixed");

  jQdeBounce(jQuery, "smartscroll", "scroll", 20); //creates the function

  jQuery(window).smartscroll(function () {
    var scroll = jQuery(window).scrollTop();

    if (scroll >= 100) {
      hometop.addClass("stickHeader");
      siteheader.addClass("stickHeader");
    } else {
      hometop.removeClass("stickHeader");
      siteheader.removeClass("stickHeader");
    }
  });

  jQuery(document).ready(function () {
    // Add smooth scrolling to all links
    jQuery("a").on("click", function (event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        jQuery("html, body").animate(
          {
            scrollTop: jQuery(hash).offset().top,
          },
          800,
          function () {
            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
          }
        );
        return false;
      } // End if
    });
  });

  /*fix for object fit in Edge and IE*/
  if (!Modernizr.objectfit) {
    jQuery(".has-object-fit").each(function () {
      var $container = jQuery(this),
        imgUrl = $container.find("img").prop("src");
      if (imgUrl) {
        $container
          .css("backgroundImage", "url(" + imgUrl + ")")
          .addClass("compat-object-fit");
      }
    });
  }

  jQuery("#slider-1").flexslider({
    animation: "fade",
    animationLoop: true,
    controlNav: true,
    slideshowSpeed: 5000,
    after: function () {
      // sets active_id to the active slide
      var active_id = jQuery(".flex-active-slide").attr("id");
    },
  });

  jQuery(".slider-2").flexslider({
    animation: "fade",
    animationLoop: true,
    controlNav: true,
    slideshowSpeed: 5000,
    after: function () {
      // sets active_id to the active slide
      var active_id = jQuery(".flex-active-slide").attr("class");
    },
  });

  jQuery("#addressSubmit").val("");

  jQuery(".accordion-detail-title").click(function () {
    jQuery(".accordion-detail-title").removeClass("active");
    jQuery(".accordion-detail-content").slideUp("normal");
    if (jQuery(this).next().is(":hidden") == true) {
      jQuery(this).next().slideDown("normal");
      jQuery(this).addClass("active");
    }
  });

  //jQuery("#addressSubmit").prepend('<img id="theImg" src="/wp-content/themes/amolasalt/images/search-icon-web.png" />')

  /*-----------------------accordion----------------------------*/
  jQuery(".product-long-description-title").click(function () {
    if (jQuery(this).hasClass("active")) {
      jQuery(this).next().slideUp("normal");
      jQuery(this).removeClass("active");
    } else {
      jQuery(this).next().slideDown("normal");
      jQuery(this).addClass("active");
    }
  });
});
