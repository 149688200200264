require("./js/mintstone-custom.js");
jQuery(document).ready(function () {
  // setTimeout(function () {
      jQuery(".slider-background-video").bgVideo({
        fullScreen: false, // Sets the video to be fixed to the full window - your <video> and it's container should be direct descendents of the <body> tag
        fadeIn: 300, // Milliseconds to fade video in/out (0 for no fade)
        pauseAfter: 32, // Seconds to play before pausing (0 for forever)
        fadeOnPause: true, // For all (including manual) pauses
        fadeOnEnd: true, // When we've reached the pauseAfter time
        showPausePlay: true, // Show pause/play button
        pausePlayXPos: "right", // left|right|center
        pausePlayYPos: "bottom", // top|bottom|center
        pausePlayXOffset: "20px", // pixels or percent from side - ignored if positioned center
        pausePlayYOffset: "0", // pixels or percent from top/bottom - ignored if positioned center
      });
  // }, 0);
});
